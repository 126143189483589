/**
 * 法币 - 冲提
 */
export enum TabType {
  // 充值
  charge = 'charge',
  // 提现
  withdraw = 'withdraw',
}

export enum PaymentManagement {
  // 当面现金交易
  FaceToFace = 'FaceToFace',
}

/** 法币收付款方式字典值 */
export enum PaymentDicCodeEnum {
  /** 支付方式字典 */
  paymentDicCode = 'pay_method_cd',
  c2cPaymentType_cd = 'c2c_payment_type_cd',
  c2cPaymentColor = 'c2c_payment_color',
  /** 开户行名称 */
  paymentBankName = 'banco_de_pagamento',
}

/** 充提固定 USDT */
export enum ChargingTypeEnum {
  usdt = 'USDT',
}

/** 法币提现 - 收款账号是否可用 */
export enum WithdrawAccountStatusEnum {
  // 不可用
  unavailable = 0,
  // 可用
  available = 1,
}

/** 加密货币资产列表枚举 */

export enum CurrencyAssetListEnum {
  /** C2C 买币 */
  c2cBuyCoins = 'c2c_buy_coins',
  /** 第三方交易 */
  thirdPartyTransactions = 'third_party_transactions',
  /** 法币充值 */
  fiatCurrency = 'fiat_currency',
  /** 填单存款 */
  fillOutDeposit = 'fill_out_deposit',
  /** 法币提现 */
  fiatCurrencyWithdrawal = 'fiat_currency_withdrawal',
}

export enum PayFormItemEnum {
  name = 'NAME',
  bankAccount = 'BANK_ACCOUNT',
  paymentDetatl = 'PAYMENT_DETAIL',
  bankName = 'BANK_NAME',
  qrCode = 'QR_CODE',
}

export const RequiredArray = [
  PayFormItemEnum.name,
  PayFormItemEnum.bankAccount,
  PayFormItemEnum.paymentDetatl,
  PayFormItemEnum.bankName,
]

export enum PaymentTypeCdEnum {
  /** 银行卡 */
  bank = 'BANK',
  /** 微信支付 */
  wechat = 'WECHAT',
  /** 支付宝 */
  alipay = 'ALIPAY',
  /** 个人税号 */
  taxIdNumber = 'TAX_ID_NUMBER',
  /** 企业 ID  */
  enterpriseID = 'ENTERPRISE_ID',
  /** 邮箱号码 */
  email = 'EMAIL',
  /** 随机码 */
  randomCode = 'RANDOM_CODE',
  /** 手机号码 */
  mobile = 'MOBILE',
}

export enum DetailsKeytypeEnum {
  name = 'NAME',
  bankOfDeposit = 'BANK_NAME',
  account = 'BANK_ACCOUNT',
  paymentDetails = 'PAYMENT_DETAIL',
  bankBranch = 'BANK_BRANCH',
  qrCodeAddr = 'QR_CODE',
  taxIdNumber = 'TAX_ID_NUMBER',
  enterpriseID = 'ENTERPRISE_ID',
  email = 'EMAIL',
  randomCode = 'RANDOM_CODE',
  mobile = 'MOBILE',
  bankCode = 'BANK_CODE',
}

export enum DetailsEditFieldEnum {
  name = 'name',
  bankOfDeposit = 'bankOfDeposit',
  account = 'account',
  paymentDetails = 'paymentDetails',
  bankBranch = 'bankBranch',
  qrCodeAddr = 'qrCodeAddr',
  taxIdNumber = 'taxIdNumber',
  enterpriseID = 'enterpriseID',
  email = 'email',
  randomCode = 'randomCode',
  mobile = 'mobile',
  bankCode = 'bankCode',
}

/** 法币充提订单枚举 */

export enum FiatOrdersEnum {
  /** 进行中 */
  progress = 'ing',
  /** 完成 */
  finish = 'finished',
}

/** 法币充提订单状态枚举 */

export enum FiatOrdersStateEnum {
  /** 成功 */
  success = 'success',
  /** 失败 */
  fail = 'fail',
}
